div,h2{
    color: black;
}

html,body{
    background-color: #fff !important;
    font-family: 'Poppins', sans-serif !important;
}



.button{
    background-color: transparent !important;
    color: #233647 !important;
    border: 1px solid;

    &:hover{
        background-color: #233647 !important;
        color: white !important;
    }
}